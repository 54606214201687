export class UserResponse {
    public result: boolean;


    constructor(src: any) {
        if (!src) {
            src = {}; 
        }
        
        this.result = src.result;
    }
}