export class User {
    public id: number;
    public firstName: string;
    public lastName: string;
    public userStatus: boolean;
    public startDateTime: Date;
    public endDateTime: Date;

    constructor(src: any) {
        if (!src) {
            src = {}; 
        }
        
        this.id = src.id;
        this.firstName = src.firstName;
        this.lastName = src.lastName;
        this.userStatus = src.userStatus;
        this.startDateTime = src.startDateTime;
        this.endDateTime = src.endDateTime;
    }
}
