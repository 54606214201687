import { Options, Vue } from 'vue-class-component';
import { RestClient } from '@/common/restApiClient';
import { UserResponse } from '@/common/models/userResponse';
import { User } from '@/common/models/user';
import { UserListResponse } from '@/common/models/userList';
import Modal  from '@/common/modal.vue';
import { CommonFunctions } from '@/common/commonFunctions';
import userStore from '@/store/user';

@Options({
  name: 'UserView',
  components: {
    Modal
  },
  props: {

  },
  emits: {
      
  },
})
export default class UserView extends Vue {
    public result = false;
    public users: User[] = [];
    public selectedUser:User = new User(null);
    public userFirstName = '';
    public selectedId = -1;
    public showModal =  false;



    public get getUserStore() {
        return userStore;
    }

    public async getName(): Promise<void> {
        try {
            const client = new RestClient();
            const result = await client.getCall(UserResponse, 'user/get/1');
            this.result = result.result;
            
        } catch (error )  {
            CommonFunctions.getErrorMsg(error);
        }
    }

    public async onUpdatedUser(user: User): Promise<void> {
        await this.onUpdate(user);
    }

    public async onUpdate(user: User): Promise<void> {       
        if(!user){
            return;
        }

        try {
            const client = new RestClient();
            const result = await client.putCall(UserResponse, 'user/update', user);
            this.result = result.result;
            
        } catch (error) {
            CommonFunctions.getErrorMsg(error);
        }
        await this.getAll();
    }

    public async onAdd(): Promise<void> {
        this.selectedUser.firstName = this.userFirstName;
        try {
            // const requestOptions = {
            //     method: 'PUT'
            //   };
              
            //   fetch('https://labmanagement.de/api/v1/user/add', {
            //     method: 'PUT', // or 'PUT'
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(this.selectedUser),
            //     })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log('Success:', data);
            //       })
            //     .catch(error => console.log('error', error));
            const client = new RestClient();
            const result = await client.putCall(UserResponse, 'user/add', this.selectedUser);
            this.result = result.result;
            
        } catch (error) {
            let errorMessage = "unknown error!";
            if (error instanceof Error) {
                errorMessage = error.message;
            }
            console.log(`UserView-errorMessage: ${errorMessage}`);
        }
        await this.getAll();
    }

    public async onDelete(): Promise<void> {
        this.selectedUser.id = this.selectedId;
        try {
            const client = new RestClient();
            const result = await client.getCall(UserResponse, 'user/delete/' + this.selectedId);
            this.result = result.result;
            
        } catch (error) {
            CommonFunctions.getErrorMsg(error);
        }
        await this.getAll();
    }

    public async getAll(): Promise<void> {
        try {
            // const requestOptions = {
            //     method: 'GET'
            //   };
              
            //   fetch("https://megabayt.de/api/v1/user/getall", requestOptions)
            //     .then(response => response.json())
            //     .then(result => console.log(result))
            //     .catch(error => console.log('error', error));
            const client = new RestClient();
            const result = await client.getCall(UserListResponse, 'user/getAll');
            this.users = result.userList;
        } catch (error) {
            CommonFunctions.getErrorMsg(error);
        }
    }
}
