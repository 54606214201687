<template>
  <div class="userView">
    userView    
  </div>
</template>
<script src="./userView.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../style/table';

</style>